<template>
  <v-row>
    <v-row justify="center" max-width="344">
      <v-col cols="10" sm="10">
        <v-form
          ref="form"
          v-model="isvalid"
          lazy-validation
        >
          <v-card>
						<v-card-title align="left" class="headline primary report-title">
							<h5>Audit Trail</h5>
						</v-card-title> 
            <br>
            <v-container>
              <v-layout wrap>
                <v-flex xs6 sm3 md2>
					<v-subheader>
						<h3>Transaction Type:</h3>
					</v-subheader>
                </v-flex>
				<v-flex xs6 sm4 md3>
					<v-autocomplete
						dense
						placeholder="Type"
						class="remove-underline"
						v-model="item.doc_type"
						:items="trans_type"
						hide-details
						item-text="trans_name"
						item-value="trans_code"
                  ></v-autocomplete>
				</v-flex>
              </v-layout>
              <v-layout wrap>
				<v-flex xs6 sm3 md2>
					<v-subheader>
						<h3>Date From:</h3>
					</v-subheader>                  
                </v-flex>
				<v-flex xs6 sm4 md3>
					<v-menu
						v-model="from_date"
						:nudge-right="40"
						transition="scale-transition"
						offset-y
						min-width="290px"
					>
						<template v-slot:activator="{ on }">
							<v-text-field
								v-model="item.date_from"
								required
								dense
								:rules="date_rules"
								return-masked-value
								v-on="on"
								mask="YYYY-MM-DD"
								placeholder="YYYY-MM-DD">
							</v-text-field>
						</template>
						<v-date-picker
							color="primary"
							v-model="item.date_from"
						></v-date-picker>
					</v-menu>
				</v-flex>
                <v-flex md1>
                </v-flex>
                <v-flex xs6 sm3 md2>
					<v-subheader>
						<h3>Date To:</h3> 
					</v-subheader>
                </v-flex>
                <v-flex xs6 sm4 md3>
					<v-menu
						v-model="to_date"
						:nudge-right="40"
						transition="scale-transition"
						offset-y
						min-width="290px"
					>
						<template v-slot:activator="{ on }">
							<v-text-field
								v-model="item.date_to"
								dense
								required
								:rules="date_rules"
								return-masked-value
								v-on="on"
								mask="YYYY-MM-DD"
								placeholder="YYYY-MM-DD">
							</v-text-field>
						</template>
						<v-date-picker
							color="primary"
							v-model="item.date_to"
						></v-date-picker>
					</v-menu>
				</v-flex>
			</v-layout><br>
			<v-layout>
				<v-flex md1>
                </v-flex>
                <v-btn dark color="blue" width="200" @click="generate">Generate</v-btn>
              </v-layout>
            </v-container> 
            <br>
            <br>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
	data: () => ({
		year: [],
		item: {},
		from_date: false,
		to_date: false,
		dataCoas: [],
		nextPageCoas: '',
		accounts: [{acct_code: 'ALL', code_name: 'All Accounts'}],
		trans_type: [
			{trans_name: 'Disbursement', trans_code: 'DM'},
			{trans_name: 'Cash Receipt', trans_code: 'CR'},
			{trans_name: 'Journal Entry', trans_code: 'JE'},
			{trans_name: 'Billing', trans_code: 'BI'},
			{trans_name: 'Collection', trans_code: 'CN'},
		],
		date_rules: [
			v => !!v || 'Date is required',
		],
		isvalid: false,
		module_name: 'Audit Trail'
	}),
	computed: {
		...mapGetters({
			coas: 'coa/coaSubs',
			coaData: 'coa/coaData',
			currUser: 'auth/currUser'

		})
	},
	watch: {
		coas: {
			handler(){
				this.getDataCoas();
			}
		},
	},
	mounted(){
		this.getYear();
		this.getCoas();
		// this.getAccount();
	},
	methods: {
		getCoas(){
			this.$store.dispatch('coa/getCoaDropdown');
		},
		getAccounts(){
			this.coaData.map(det => {
				this.accounts.push(det);
			});
		},
		getYear() {
			let data = [];
			var i;
			data.push({value: '2020', text: '2020'});
			for (i = 1; i < 5; i++) {
				let det = 2020 - i;
				det.toString();
				data.push({value: det, text: det});
			}
			this.year = data;
		},
		generate() {
			//this.item.month.toString();
			this.item.user = this.currUser.user_first_name + ' ' + this.currUser.user_last_name;
			let routeData = this.$router.resolve({path: '/report-auditTrail/'+btoa(JSON.stringify(this.item)), data: this.item});
			window.open(routeData.href, '_blank'); 
		},
		async getDataCoas(){
			if(this.coaData.length === 0){
				this.dataCoas = this.coas.data;
				this.nextPageCoas = this.coas.next_page_url;
				let page = 2;

				while (this.nextPageCoas !== null){
					await this.$store.dispatch('coa/getCoaData', page).then(response => {
						this.nextPageCoas = response.data.data.next_page_url;
						response.data.data.data.forEach(details => {
							this.dataCoas.push(details);
						});
					});
					page++;
				}
				this.$store.commit('coa/SET_COA_DATA', this.dataCoas);
			}
		},
	}
};
</script>
<style>
.report-title {
	color: #FFFFFF;
}
</style>