<template>
	<div class="home">
		<auditTrail></auditTrail>
	</div>
</template>

<script>
import auditTrail from '@/components/pages/report/auditTrail/auditTrailRequest.vue';

export default {
	components: {
		'auditTrail': auditTrail,
	}
};
</script>

<style>

</style>
